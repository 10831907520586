<template>
  <b-input-group :style="$attrs.style">
    <b-input-group-prepend>
      <b-button
        :size="size"
        variant="outline-secondary"
        :disabled="!selectedDay || $attrs.disabled || minDate"
        :title="$t('datepicker.prevDay')"
        @click="changeDay('prev')"
      >
        <BIconChevronLeft />
      </b-button>
    </b-input-group-prepend>
    <datepicker-wrapper v-model="selectedDay" :size="size" :disabled="disabled" v-bind="$attrs" v-on="$listeners" />
    <b-input-group-append>
      <b-button
        :size="size"
        variant="outline-secondary"
        :disabled="!selectedDay || $attrs.disabled || maxDate"
        :title="$t('datepicker.nextDay')"
        @click="changeDay('next')"
      >
        <BIconChevronRight />
      </b-button>
    </b-input-group-append>
  </b-input-group>
</template>

<script>
import DatepickerWrapper from '@/components/DatepickerWrapper.vue';
import { BInputGroupAppend, BInputGroupPrepend, BIconChevronLeft, BIconChevronRight } from 'bootstrap-vue';

export default {
  components: { BInputGroupAppend, BInputGroupPrepend, BIconChevronLeft, BIconChevronRight, DatepickerWrapper },
  inheritAttrs: false,
  model: {
    prop: 'inputValue',
    event: 'onSelect',
  },
  props: {
    inputValue: {
      type: [Date, String],
      default: undefined,
    },
    size: {
      type: String,
      default: 'sm',
    },
    disabled: {
      type: Boolean,
      default: undefined,
    },
  },
  data() {
    return {};
  },
  computed: {
    selectedDay: {
      get() {
        return this.inputValue;
      },
      set(value) {
        this.$emit('onSelect', value);
      },
    },
    minDate() {
      if (this.$attrs.min && new Date(this.inputValue) <= new Date(this.$attrs.min)) return true;
      else return false;
    },
    maxDate() {
      if (this.$attrs.max && new Date(this.inputValue) >= new Date(this.$attrs.max)) return true;
      else return false;
    },
  },
  methods: {
    changeDay(param) {
      if (!this.selectedDay) return;
      const currentSelectedDay = new Date(this.selectedDay);
      const index = param === 'prev' ? 0 : 2;
      const newDay = new Date(currentSelectedDay.getFullYear(), currentSelectedDay.getMonth(), currentSelectedDay.getDate() + index).toISOString().slice(0, 10);
      this.selectedDay = newDay;
      this.$emit('input');
    },
  },
};
</script>
