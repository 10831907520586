<template>
  <div v-if="mediaPlan">
    <template v-if="canReadTable">
      <div class="d-flex flex-wrap align-items-center justify-content-between">
        <div class="d-flex gap-3 mb-2">
          <datepicker-with-side-buttons
            v-model="mediaPlanTableFilter.date"
            reset-button
            size="sm"
            dropup
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            :min="mediaPlan.date_from"
            :max="mediaPlan.date_to"
            data-automatic="mp-table-date-filter"
            style="width: 250px"
            :disabled="!isListView"
            @input="loadMediaPlanTable('filter')"
          />
          <MultiSelect
            v-model="mediaPlanTableFilter.program_release"
            style="width: 250px"
            class="multiselect-sm"
            :options="mediaPlanTableFilter.programReleasesList"
            :placeholder="$t('table.selectProgramRelease')"
            label="name"
            track-by="name"
            data-automatic="mp-table-program-release-filter"
            @input="loadMediaPlanTable('filter')"
          />
          <MultiSelect
            v-model="mediaPlanTableFilter.commercial"
            style="width: 250px"
            class="multiselect-sm"
            :options="mediaPlanTableFilter.commercialsList"
            :placeholder="$t('table.commercial')"
            label="id_name_mark"
            track-by="id"
            data-automatic="mp-table-commercial-filter"
            @input="loadMediaPlanTable('filter')"
          />
          <select
            v-model="selectedCommercialDuration"
            class="custom-select custom-select-sm w-125px"
            :style="{ visibility: isListView ? 'visible' : 'hidden' }"
          >
            <option :value="null" disabled>{{ $t('table.duration') }}</option>
            <option :value="0">{{ $t('table.all') }}</option>
            <option v-for="(dur, index) in mediaPlanTableFilter.durationsList" :key="index" :value="dur">{{ dur }}</option>
          </select>
        </div>
        <div class="d-flex gap-2 mb-2">
          <b-button
            v-if="$checkPermissions('spot.replace_many')"
            v-b-modal.replace-spots
            size="sm"
            :disabled="mediaPlan.is_closed"
            :title="mediaPlan.is_closed ? $t('table.mpIsClosed') : ''"
          >
            {{ $t('table.replaceSpots') }}
          </b-button>
          <b-button
            v-if="$checkPermissions('spot.delete_many')"
            v-b-modal.delete-spots
            size="sm"
            :disabled="mediaPlan.is_closed"
            :title="mediaPlan.is_closed ? $t('table.mpIsClosed') : ''"
          >
            {{ $t('table.deleteSpots') }}
          </b-button>
          <b-button
            v-if="
              $checkPermissions('spot.fix_many') &&
              (canFixLowPriority || (!canFixLowPriority && mediaPlan && (mediaPlan.placement_type_id === 1 || mediaPlan.placement_type_id === 2)))
            "
            v-b-modal.fix-spots
            size="sm"
            :disabled="mediaPlan.is_closed"
            :title="mediaPlan.is_closed ? $t('table.mpIsClosed') : ''"
          >
            {{ $t('table.fixSpots') }}
          </b-button>
          <b-button
            v-if="
              $checkPermissions('booking.channel_copy') && (!isAMA || (isAMA && mediaPlanTable && mediaPlanTable.meta && mediaPlanTable.meta.allow_mass_copy))
            "
            v-b-modal.copy-booking
            size="sm"
            :disabled="mediaPlan.is_closed"
            :title="mediaPlan.is_closed ? $t('table.mpIsClosed') : ''"
          >
            {{ $t('table.copyBooking') }}
          </b-button>
          <b-button v-if="$checkPermissions('export.mediaplan')" :disabled="exporting" size="sm" @click="exportMediaPlanTable">
            {{ exporting ? $t('table.exporting') : $t('table.export') }}
          </b-button>

          <ModalReplaceSpots :current-media-plan="mediaPlan" @updateData="$emit('updateData')" />
          <ModalDeleteSpots :current-media-plan="mediaPlan" @updateData="$emit('updateData')" />
          <ModalFixSpots :current-media-plan="mediaPlan" @updateData="$emit('updateData')" />
          <ModalCopyBooking :current-media-plan="mediaPlan" @updateData="$emit('updateData')" />
        </div>
      </div>
      <MediaPlanTableList :media-plan="mediaPlan" :is-list-view="isListView" :selected-duration="selectedCommercialDuration" />
    </template>
    <div class="d-flex gap-2 align-items-center justify-content-end">
      <b-button
        v-if="$checkPermissions('mediaplan.budget_calculate')"
        size="sm"
        :disabled="!(mediaPlan && !loading) || recalculating"
        @click="calculateWgrpAndBudgets()"
      >
        {{ recalculating ? $t('table.recalculating') : $t('table.recalculate') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import MultiSelect from '@/components/MultiSelect';
import { mapGetters } from 'vuex';
import downloadFileHandler from '@/mixins/downloadFileHandler';
import errorsHandler from '@/utils/errorsHandler';
import DatepickerWithSideButtons from '@/components/DatepickerWithSideButtons.vue';

export default {
  components: {
    MultiSelect,
    DatepickerWithSideButtons,
    ModalReplaceSpots: () => import('@/components/Booking/ModalReplaceSpots'),
    ModalDeleteSpots: () => import('@/components/Booking/ModalDeleteSpots'),
    ModalFixSpots: () => import('@/components/Booking/ModalFixSpots'),
    ModalCopyBooking: () => import('@/components/Booking/ModalCopyBooking'),
    MediaPlanTableList: () => import('@/components/Projects/MediaPlanTableList'),
  },
  mixins: [downloadFileHandler],
  props: {
    mediaPlan: {
      required: true,
      type: Object,
    },
    isListView: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
  },
  data() {
    return {
      mediaPlanTableFilter: {
        date: null,
        commercial: null,
        program_release: null,
        commercialsList: [],
        programReleasesList: [],
        durationsList: [],
      },
      exporting: false,
      recalculating: false,
      selectedCommercialDuration: null,
    };
  },
  computed: {
    ...mapGetters({
      isLocale: 'isLocale',
      isThemeHeader: 'isTheme',
      programReleasesDistinctNames: 'getProgramReleasesDistinctNames',
      mediaPlanTable: 'getMediaPlanTable',
      isAMA: 'getIsCurrentUserAgencyManager',
    }),
    canReadTable() {
      return this.$checkPermissions('mediaplan.read_table');
    },
    canFixLowPriority() {
      return this.$checkPermissions('spot.fix_low_priority');
    },
  },
  watch: {
    mediaPlan: {
      immediate: true, // run on mount
      handler(newVal, oldVal) {
        //if MP selection is changed
        if (!this.mediaPlan) return;
        this.clearMediaPlanTableFilters();
        this.loadMediaPlanTable();
      },
    },
    async isListView(value) {
      //reset date filter for table view (this API filter works only with list view)
      if (!value && this.mediaPlanTableFilter.date) {
        this.mediaPlanTableFilter.date = null;
      }
      //if table view toggle is changed
      await this.loadMediaPlanTable();
    },
  },
  mounted() {},
  destroyed() {
    //if unmounted - need to clear
    this.$store.commit('clearMPtable');
  },
  methods: {
    async loadMediaPlanTable(param) {
      if (!(this.canReadTable && this.mediaPlan)) return;
      await this.$store.dispatch('GET_MEDIA_PLANS_ID_TABLE', {
        id: this.mediaPlan.id,
        data: {
          type: this.isListView ? 'list' : undefined,
          program_release_name: this.mediaPlanTableFilter.program_release?.name,
          commercial_id: this.mediaPlanTableFilter.commercial?.id,
          date: this.isListView && this.mediaPlanTableFilter.date ? this.mediaPlanTableFilter.date : undefined,
        },
      });
      if (!param) {
        // don't do if call was from filters
        await this.$store.dispatch('GET_PROGRAM_RELEASES_DISTINCT_NAMES', {
          'filter[channel_id]': this.mediaPlan?.channel_id,
          'filter[broadcast_day_from]': this.mediaPlan?.date_from,
          'filter[broadcast_day_to]': this.mediaPlan?.date_to,
          per_page: 2000,
        });
        this.mediaPlanTableFilter.programReleasesList = [];
        if (this.programReleasesDistinctNames) this.mediaPlanTableFilter.programReleasesList = [{ name: undefined }, ...this.programReleasesDistinctNames];
        this.mediaPlanTableFilter.commercialsList = [];
        this.mediaPlanTableFilter.durationsList = [];
        if (!(this.mediaPlan && this.mediaPlan.commercials)) return;
        this.mediaPlanTableFilter.commercialsList = [
          { id: null, id_name_mark: this.$i18n.t('table.all') },
          ...new Map(this.mediaPlan.commercials.map(function (item) {
              item['id_name_mark'] = `[${item['id']}] ${item['name']} (${item['mark']})`;

              return [item['id'], item];
          })).values(),
        ];
        this.mediaPlanTableFilter.durationsList = [...new Map(this.mediaPlan.commercials.map((item) => [item['duration'], item.duration])).values()];
      }
    },

    async exportMediaPlanTable() {
      if (!this.mediaPlan?.id) return;
      this.exporting = true;
      if (this.isListView) {
        await this.$store.dispatch('GET_MEDIA_PLANS_EXPORT_LIST', {
          id: this.mediaPlan.id,
          data: {
            format: 'xlsx',
            program_release_name: this.mediaPlanTableFilter.program_release?.name,
            commercial_id: this.mediaPlanTableFilter.commercial?.id,
            date: this.mediaPlanTableFilter.date ? this.mediaPlanTableFilter.date : undefined,
          },
          handler: (res) => this.prepareAndDownloadFile(res),
        });
      } else {
        await this.$store.dispatch('GET_MEDIA_PLANS_EXPORT', {
          id: this.mediaPlan.id,
          data: {
            format: 'xlsx',
          },
          handler: (res) => this.prepareAndDownloadFile(res),
        });
      }
      this.exporting = false;
    },

    clearMediaPlanTableFilters() {
      this.mediaPlanTableFilter.program_release = null;
      this.mediaPlanTableFilter.date = null;
      this.mediaPlanTableFilter.commercial = null;
      this.selectedCommercialDuration = null;
      this.mediaPlanTableFilter.commercialsList = [];
      this.mediaPlanTableFilter.programReleasesList = [];
      this.mediaPlanTableFilter.durationsList = [];
    },

    async calculateWgrpAndBudgets() {
      if (!this.mediaPlan?.id) return;
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('table.allIsCalculated')}. ${this.$i18n.t('table.areYouSure')}`, {
          title: this.$i18n.t('table.confirmAction'),
          size: 'sm',
          okTitle: this.$i18n.t('table.yes'),
          cancelTitle: this.$i18n.t('table.no'),
          autoFocusButton: 'ok',
          id: 'confirm-recalculate-mp-modal',
        })
        .then((value) => {
          if (value) {
            this.recalculating = true;
            this.$store.dispatch('POST_RECALCULATE_WGRP_BUDGETS', {
              mediaplan_id: this.mediaPlan.id,
              handler: () => {
                this.$notify({
                  type: 'success',
                  title: this.$i18n.t('alert.success'),
                });
                this.$emit('updateData');
                this.recalculating = false;
              },
              handlerError: (errors) => {
                errorsHandler(errors, this.$notify);
                this.recalculating = false;
              },
            });
          }
        })
        .catch(() => {});
    },
  },
};
</script>
